<template>
<div>
    <div v-if="mails.length">
        <div class="row">
            <div class="col-md-6" v-for="(mail, index) in paginatedMails" :key="index">
                <div class="card">
                    <div class="card-body">
                        <h2 class="text-uppercase">{{mail.subject}}</h2>
                        <hr class="my-2"/>
                        <div v-html="mail.content" class="">

                        </div>
                        <hr class="my-2"/>
                        <div class="text-muted">
                            <div class="row">
                                <div class="col-6">
                                    <div class="">
                                        <p class="text-truncate mb-1 font-14">Date Dispatched</p>
                                        <h5 class="my-0 font-14"><span class="badge badge-success"> {{ mail.created_at | date_parse(true)}}</span></h5>
                                    </div>
                                </div>
                                 <div class="col-6 d-flex justify-content-end">
                                    <div class="">
                                       <b-dropdown right toggle-class="text-body p-0" variant="black">
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-dots-vertical font-20"></i>
                                            </template>
                                            <b-dropdown-item @click.prevent="deleteItem(mail.id)" >Delete</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <div class="text-md-right mt-2 pagination-rounded">
                    <b-pagination v-model="pagination.currentPage" :total-rows="mails.length" :per-page="pagination.perPage" @input="onPageChange"></b-pagination>
                </div>
            </div>
        </div>
    </div>
    <p v-if="!paginatedMails.length"><em>No marketing mail to show </em></p>
</div>
</template>

<script>

export default {
    props: {
        mails:{
            type: Array,
            required: true
        }
    },
    data() {
        return {
            pagination:{
                currentPage: 1,
                perPage: 4,
                startIndex: 1,
                endIndex: 10,
            },
        };
    },
    computed: {
        paginatedMails(){
            return this.mails.slice(
                this.pagination.startIndex,
                this.pagination.endIndex
            );
        },
    },
    methods: {
        deleteItem(itemId){
            this.$emit("deleteItem",itemId)
        },
        onPageChange() {
            this.pagination.startIndex = (this.pagination.currentPage - 1) * this.pagination.perPage;
            this.pagination.endIndex = (this.pagination.currentPage - 1) * this.pagination.perPage + this.pagination.perPage;
        },
    },
    created() {
        this.pagination.startIndex = 0;
        this.pagination.endIndex = this.pagination.perPage;
    }
};
</script>


