<template>
  <div>
    <div class="row justify-content-start">
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="target_email">
            Buyer's email
          </label>
          <input type="text" id="target_email" disabled :value="shownUser.email" class="form-control" placeholder="target email" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="subject">
            Subject
          </label>
          <input type="text" v-model="data.subject" id="subject"
            class="form-control" placeholder="Write subject" v-validate="'required'" name="subject"/>
          <span class="text-danger font-sm"  
          v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
        </div>
      </div>
      <div class="col-12">
        <quill-editor v-model="data.content" style="min-height: 100px;" :options="editorOption" />
      </div>
    </div>
    <div class="form-group text-left mt-3 mb-0">
        <button class="btn btn-primary" @click.prevent="createData()" type="button">Send</button>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default{
  props:{
    shownUser:{
      type: Object,
      default() { return {}}
    }
  },
  components:{
    quillEditor,
  },
  data() {
    return {
      data: {
        content: "",
        subject: "",
        user_id: this.shownUser.id 
      },
      editorOption: {
        theme: "snow",
        placeholder: "Enter Description",
        modules: {
          toolbar: [
            [{
                font: [],
                },
                {
                 size: [],
                },
            ],
            ["bold", "italic", "underline", "strike"],
            [{
                    color: [],
                },
                {
                    background: [],
                },
            ],
            [{
                    script: "super",
                },
                {
                    script: "sub",
                },
            ],
            [{
                header: [false, 1, 2, 3, 4, 5, 6],
                },
                "blockquote",
                "code-block",
            ],
            [{
                    list: "ordered",
                },
                {
                    list: "bullet",
                },
                {
                    indent: "-1",
                },
                {
                    indent: "+1",
                },
            ],
            [
                "direction",
                {
                  align: [],
                },
            ],
            ["link"],
            ["clean"],
          ],
        },
      },
    }
  },
  methods: {
    createData() {
      this.$validator.validateAll().then(result => {
        if (!result || !this.data.content) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$emit('createData',formData);
      })
    },
    resetForm() {
      this.data = {
        content: "",
        subject: "",
        user_id: this.shownUser.id,
      }
    },
  },
}
</script>
