<template>
<div>
    <!-- end row -->
    <div v-if="!isLoading" class="row">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-body">
                    <div class="mb-3">
                        <a class="btn text-white btn-primary" href="javascript: void(0);" @click="showCreateModal = true">
                            <i class="mdi mdi-email-edit-outline mr-1"></i> Compose mail
                        </a>
                    </div>
                    <div class="form-group mb-3">
                        <label>Users</label>
                        <multiselect @input="fetchMail($event)" v-model="shownUser" placeholder="--select user--" label="first_name" track-by="id" :options="users" :option-height="104" :show-labels="false">
                            <template slot="singleLabel" slot-scope="props">
                                <img style="width:20px;height:20px;" class="mr-2 option__image"
                                :src="`${$store.state.domain}/${props.option.image}`"
                                :alt="props.option.first_name || props.option.username">
                                <span class="option__desc"><span class="option__title">{{ props.option.email }}</span>
                                </span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="d-flex align-items-center">
                                    <img style="width:40px;height:40px;" class="mr-2 rounded-circle option__image" :src="`${$store.state.domain}/${props.option.image}`" :alt="props.option.first_name">
                                    <div class="option__desc">
                                        <p class="option__title mb-0">{{ props.option.first_name || props.option.username }} {{ props.option.last_name }}</p>
                                        <span class="">{{ props.option.email }}</span>
                                    </div>
                                </div>
                            </template>
                        </multiselect>
                        <div v-if="shownUser" class="mt-3">
                            <label>{{shownUser.role == 'buyer'? 'Orders placed by' : 'view'}} <router-link style="text-decoration:underline;" :to="`/users/${shownUser.id}`">{{shownUser.first_name || shownUser.username}}</router-link></label>
                            <div>
                                <router-link class="mr-2" style="text-decoration:underline;" v-for="(order, orIndex) in orders" 
                                :key="orIndex" :to="`/orders/${order.id}`">{{order.id}}_{{order.status}}</router-link>
                                <em v-if="!orders.length">No order to show</em>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card-box -->
            </div>
        </div>
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                   <mail-list :mails="mails"
                   @deleteItem="deleteItem($event)" />
                </div>
            </div>
        </div>
    </div>
    <b-modal size="md" centered id="modal-1" v-model="showCreateModal" title="Compose mail" header-close-variant="primary" title-class="font-18" hide-footer>
        <create-mail ref="createMailComponent"
         :shownUser="shownUser"
         @createData="createData($event)"/>
    </b-modal>
    <div v-if="isLoading" class="row">
        <div class="col-lg-4 mb-3 mb-lg-0">
            <b-skeleton animation="wave" height="15rem" width="100%"></b-skeleton>
        </div>
        <div class="col-lg-8">
            <b-skeleton animation="wave" height="30rem" width="100%"></b-skeleton>
        </div>
    </div>
</div>
</template>

<script>
import CreateMail from "./components/CreateMail.vue"
import MailList from "./components/MailList.vue"
import Multiselect from 'vue-multiselect'

export default {
    components: {
        CreateMail,
        Multiselect,
        MailList
    },
    data() {
        return {
            showCreateModal:false,
            mails: [],
            orders:[],
            shownUser: null,
            isLoading: true
        };
    },
    computed: {
        users(){
            return this.$store.state.userList.users.filter((item) => item.role == 'buyer' || item.role == 'worker' )
        }
    },
    methods: {
        createData(formData){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post("/marketing-mails/create",formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.alertSuccess(response.data.success)
                    this.mails.unshift(response.data.mail)
                    this.resetForm();
                }else if(response.data.errors){
                    response.data.errors.forEach((error) => { this.alertError(error)})
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=> {console.log(error)  })
        },
        deleteItem(itemId){
            if(!itemId){return}
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete the selected item!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                this.$store.dispatch("changeLoaderValue",true)
                this.$http.delete(`/marketing-mails/${itemId}/delete`)
                    .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.alertSuccess(response.data.success)
                            const itemIndex = this.mails.findIndex((item) => item.id == itemId)
                            this.mails.splice(itemIndex, 1)
                        }else{
                            this.alertError(response.data.error)
                        }
                    }).catch((error) => { console.log(error);})
                }
            });
        },
        resetForm(){
            this.$refs.createMailComponent.resetForm()
            this.showCreateModal = false
        },
        fetchMail(user = this.users.length ? this.users[0] : null) {
            if(!user){return}
            this.isLoading = true
            this.$http.get(`/marketing-mails/${user.id}/show`)
            .then(response => {
                this.isLoading = false
                if(response.data.success){
                    this.shownUser = {...response.data.user}
                    this.mails = [...response.data.user.mails]
                    this.orders = [...response.data.user.orders]
                }
            }).catch(error => { console.log(error) })
        },
        initializeUser(){
            if(this.$route.query.user_id){
                this.fetchMail({id: this.$route.query.user_id});
            }else{
                this.fetchMail();
            }
        }
    },
    created() {
        if(!this.$store.state.userList.users.length){
            this.$store.dispatch("userList/fetchUsers")
            .then((response)=>{
                if(response.data.success){
                    this.initializeUser()
                }
            });
        }else{
            this.initializeUser()
        }
    },
    beforeUnmount(){
        this.mails = [];
        this.orders = []
    }
};
</script>


